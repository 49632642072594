/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Ashutosh's Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Ashutosh Hathidara Portfolio",
    type: "website",
    url: "http://ashutoshhathidara.com/",
  },
};

//Home Page
const greeting = {
  title: "Hi, I am Johnny Wu!",
  logo_name: "Johnny Wu",
  subTitle:
    "With 5 years in software engineering, I excel in front-end development and am proficient as a full-stack developer. Dedicated to crafting optimal digital solutions.",
  resumeLink:
    "https://drive.google.com/file/d/1TW7Q9-IwgMxyY0omNJtD02tHMq8-DNvU/view?usp=sharing",
  githubProfile: "https://github.com/PHANTOMS0308",
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/PHANTOMS0308",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "Gmail",
    link: "mailto:johnnywu.developer@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
];

const skills = {
  data: [
    {
      title: "Front End Development",
      fileName: "FrontEndImg",
      skills: [
        "⚡ Possess expertise in React and its expansive ecosystem, including mastery of tools like Redux, React Router, React Query. Furthermore, Predominantly utilize Next.js for server-side rendering (SSR) and static site generation (SSG).",
	"⚡ Proficient in React Native, enabling the creation of high-performance, cross-platform mobile applications that mirror their web counterparts in terms of quality and user experience.",
	"⚡ Familar with Vue.js, leveraging its powerful reactive data system, single-file components, and directive-driven architecture to craft dynamic and efficient web applications that prioritize user experience and maintainability. ",
        "⚡ Command a robust foundation in core web technologies, including HTML5, CSS3, and JavaScript (ES6+), coupled with expertise in TypeScript. This foundational grasp enables intricate work at a lower level, excelling in environments without the need for frameworks.",
        "⚡ Collaborated closely with design teams to transform Figma designs into pixel-perfect, WCAG-compliant interfaces, utilizing CSS frameworks such as Tailwind and SASS",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "Tailwind CSS",
          fontAwesomeClassname: "devicon:tailwindcss",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "BootStrap",
          fontAwesomeClassname: "devicon:bootstrap",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "TypeScript",
          fontAwesomeClassname: "devicon:typescript",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "React/React Native",
          fontAwesomeClassname: "devicon:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Redux",
          fontAwesomeClassname: "skill-icons:redux",
          style: {
            color: "#CB3837",
          },
        },

        {
          skillName: "React Query",
          fontAwesomeClassname: "logos:react-query-icon",
          style: {
            color: "#663399",
          },
        },
        {
          skillName: "React Router",
          fontAwesomeClassname: "devicon:reactrouter",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "Jest",
          fontAwesomeClassname: "skill-icons:jest",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "JQuery",
          fontAwesomeClassname: "devicon:jquery",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "NextJS",
          fontAwesomeClassname: "skill-icons:nextjs-dark",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Webpack",
          fontAwesomeClassname: "devicon:webpack",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Vue",
          fontAwesomeClassname: "devicon:vuejs",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "NuxtJS",
          fontAwesomeClassname: "skill-icons:nuxtjs-dark",
          style: {
            color: "#2C8EBB",
          },
        },
      ],
    },
    {
      title: "Back End Developement",
      fileName: "BackEndImg",
      skills: [
        "⚡ Proficient in building robust back-end systems, equally skilled with GraphQL and RESTful API design using tools like Express.js, Node.js and Apollo Server.",
        "⚡ Demonstrated expertise in managing databases such as MongoDB, MySQL, and PostgreSQL with a focus on optimization, scalability, and data engineering",
        "⚡ Experienced in deploying applications in cloud environments, especially AWS EC2, backed by a thorough understanding of Linux system and bash scripting",
      ],
      softwareSkills: [
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "devicon:nodejs",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "logos:npm-icon",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "ExpressJS",
          fontAwesomeClassname: "skill-icons:expressjs-dark",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "GraphQL",
          fontAwesomeClassname: "logos:graphql",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Apollo",
          fontAwesomeClassname: "skill-icons:apollo",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "devicon:postgresql",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "devicon:mysql",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "skill-icons:mongodb",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Linux",
          fontAwesomeClassname: "devicon:linux",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Bash",
          fontAwesomeClassname: "devicon:bash",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "devicon:git",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "skill-icons:aws-dark",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Postman",
          fontAwesomeClassname: "devicon:postman",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "skill-icons:docker",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "logos:kubernetes",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
      ],
    },
  ],
};

// Experience Page
const experience = {
  title: "My Experience",
  description:
    "In my 5-year journey, I've collaborated with remarkable individuals from esteemed companies. Their insights have enriched my software engineering skills and taught me office dynamics beyond what's learned in classrooms.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Senior Frontend Software Engineer",
          company: "JustWorks",
          company_url: "https://www.justworks.com/",
          logo_path: "justworks_logo.png",
          duration: "January 2022 - Present",
          location: "New York, NY",
          description: "At JustWorks, I led the development of the JustWorks PayRoll application, a critical tool streamlining payroll for small businesses. As a senior developer, I was entrusted with overseeing system design and component architecture, making pivotal technical decisions. My role was instrumental in shaping the product line, emphasizing Agile collaboration and ensuring the software's robustness and efficiency.",
          color: "#0879bf",
        },
        {
          title: "Frontend Developer",
          company: "SquareSpace Inc.",
          company_url: "https://www.squarespace.com/",
          logo_path: "squarespace_logo.png",
          duration: "February 2020 - November 2021",
          location: "New York, NY",
          description:
            "At SquareSpace, I significantly contributed to the 'Engage' module, offering users advanced marketing tools. Developed features like promotional pop-ups and custom code injections, integrating with cloud services. Upheld rigorous testing standards to ensure feature robustness within the SquareSpace ecosystem.",
          color: "#9b1578",
        },
        {
          title: "FrontEnd Developer",
          company: "Zola Inc.",
          company_url: "https://www.zola.com/",
          logo_path: "zola_logo.png",
          duration: "June 2018 - December 2019",
          location: "New York, NY",
          description:
            "At Zola, I was instrumental in crafting the Album feature for the e-commerce platform. My team and I built immersive UI components, enhancing user interaction. Collaborated with a team to ensure optimal code quality and UI design while actively participating in the Agile framework.",
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Education",
      experiences: [
        {
          title: "Master of Science in Data Analytics",
          company: "Binghamton University",
          company_url: "https://www.binghamton.edu/",
          logo_path: "BU.png",
          duration: "Vestal, NY",
          description:
            "This degree wasn't just about analyzing data; it was an immersive experience into advanced data structures, algorithms, and database management. These elements are pivotal for any full-stack web developer. Moreover, my data analytics vantage point has enhanced my collaborations with data science teams, ensuring a cohesive and informed approach in projects that bridge software engineering and data science.",
          color: "#004333",
        },
        {
          title: "Bachelor of Science in Financial Economics",
          company: "Binghamton University",
          company_url: "https://www.binghamton.edu/",
          logo_path: "BU.png",
          duration: "Vestal, NY",
          description:
            "Beyond the realm of economic principles and intricate financial models, my time during this degree was rich with self-driven coding ventures. The countless hours I invested in self-teaching web development helped cement a robust foundation for my future in the tech world. Additionally, the logical reasoning and problem-solving aptitude inherent to economics have seamlessly translated into my endeavors as a software engineer, underlining the critical thinking vital for effective web development.",
          color: "#ee3c26",
        },
      ],
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "selfie.jpg",
    description:
      "Passionate about tech and collaboration, I'm always available via email. Whether you have questions, project ideas, or just want to chat about the industry, I'm eager to connect",
  },
  emailSection: {
    title: "Email",
    subtitle:
      "I'm regularly active on my email, so don't hesitate to drop a message. To ensure a productive conversation, please provide some context or details about the topic you'd like to discuss.",
    link: "johnnywu.developer@gmail.com",
    avatar_image_path: "email_image.svg",
  },
  phoneSection: {
    title: "Phone",
    subtitle:
      "While I might not always be immediately available by phone, I'd appreciate a heads-up via SMS or email first. It ensures I'm prepared and can allocate the right amount of time for our call.",
    link: "3479195392",
    avatar_image_path: "phone_image.svg",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  experience,
  contactPageData,
};
